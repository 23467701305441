import * as React from "react";
import Layout from "../../components/layout";
import Block from "../../components/block";
import styled from "styled-components";
import Footer from "../../components/footer";
import { BREAKPOINTS } from "../../consts";
import { useMediaQuery } from "react-responsive";
import TextPageContainer from "../../components/text-page-container";


const Page = styled.div`
  article {
    ul {
      list-style: none;
    }
    h2 {
      margin-top: 2rem;
    }
  }
`;

const TermsOfUsePage = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout title={"Terms of Use"} description={"Terms of Use for Bifrost Wallet."}>
        <Block>
          <TextPageContainer>
            <h1 className="page-title">Terms of Use</h1>
            <p className="detail">Last updated: January 8, 2023</p>
            <article>
              <h2>1. General</h2>
              <p>
                You (or the entity that you represent) are about to use the
                Bifrost Wallet. Bifrost Wallet is a non-custodial mobile wallet
                app for crypto assets, which enables you to generate and manage
                sets of private and public keys in order to configure crypto
                asset transactions and monitor your crypto assets. You may also
                browse and access Third-Party Services (as defined below).
              </p>
              <p>
                Before you proceed, we need you to carefully read, understand
                and accept these terms of use ("Terms") of the Bifrost Wallet.
                When you accept the Terms or otherwise proceed using any of our
                services provided in connection with the Bifrost Wallet software
                ("Services"), the Terms will form a binding contract (the
                "Contract") between you and Towo Labs AB, reg. no. 559209-4873,
                a Swedish limited liability company ("Towo Labs", "we", "our”,
                or "us"), which provides Bifrost Wallet to you.
              </p>
              <p>
                It is your own responsibility to educate yourself and keep
                yourself informed about blockchain technology, crypto assets and
                cybersecurity best practices. We are providing you with our
                Bifrost Wallet software, but you have to secure your Bifrost
                Wallet and handle your crypto assets by yourself and at your own
                risk.
              </p>
              <p>
                You acknowledge that the Contract are concluded between Towo
                Labs and you, and not with Apple, Inc. or any of its affiliates
                (jointly "Apple") or Google, Inc. or any of its affiliates
                (jointly "Google"). Towo Labs, not Apple or Google, are solely
                responsible for Bifrost Wallet and the Services.
              </p>
              <h2>2. Definitions</h2>
              <p>
                When used in these Terms, the following words with an initial
                capital letter shall be defined as described below. Reference to
                the word in singular includes a reference to the word in plural
                and vice versa.
              </p>
              <p>
                <span className="bold-section">"Crypto Assets" </span>shall mean
                any type of digital asset, cryptocurrency or non-fungible token
                which can be transmitted by use of blockchain technologies,
                including but not limited to Bitcoin, Ethereum, XRP and other
                crypto assets.
              </p>
              <p>
                <span className="bold-section">"Fork" </span>shall mean a change
                to the underlying protocol of a blockchain network that results
                in more than one version of a Crypto Asset, the result of which
                may be one or more versions that are not supported on Bifrost
                Wallet.
              </p>
              <p>
                <span className="bold-section">"Materials" </span>shall mean
                content, documentation as well as source and object codes for
                Bifrost Wallet and the Services.
              </p>
              <p>
                <span className="bold-section">"PIN Code" </span> shall mean the
                numeric password chosen by you to unlock the Bifrost Wallet.
              </p>
              <p>
                <span className="bold-section">"Private Keys" </span>shall mean
                critical pieces of data used to authorize outgoing transactions
                on blockchain networks.
              </p>
              <p>
                <span className="bold-section">"Recovery Phrase" </span>shall
                mean a confidential combination of human readable words,
                generated by Bifrost Wallet, from which your Private Keys are
                derived. The Recovery Phrase is used to back-up and restore
                access to your Crypto Assets on another mobile device or in
                another compatible Wallet.
              </p>
              <p>
                <span className="bold-section">"Services" </span>shall mean the
                services Towo Labs provides or may provide to you in Bifrost
                Wallet.
              </p>
              <p>
                <span className="bold-section">"Third Party Services" </span>
                refers to applications, software or other Materials that are
                hosted, developed or operated by a third-party, including but
                not limited to Crypto Asset exchanges, staking service providers
                or similar.
              </p>
              <p>
                <span className="bold-section">"Usage Rules" </span>means the
                rules applicable for users in Apple Media Services Terms and
                Conditions and in Google Play Terms of Service.
              </p>
              <p>
                <span className="bold-section">"Wallet" </span>shall mean a
                software application, which can be used to generate and manage
                sets of private keys and public keys, configure transactions of
                Crypto Assets and monitor their balance.
              </p>
              <h2>3. What is Bifrost Wallet?</h2>
              <p>
                <span className="bold-section">3.1. </span>Bifrost Wallet is our
                non-custodial software wallet mobile application which provides
                you with a convenient and secure way to manage your Private Keys
                for your Crypto Assets derived from your Recovery Phrase, which
                are generated by your copy of the Bifrost Wallet after
                installation or that you import to Bifrost Wallet.
              </p>
              <p>
                <span className="bold-section">3.2. </span>Bifrost Wallet allows
                you to view your portfolio of Crypto Assets, browse Third-Party
                Services and perform operations on various blockchain networks
                in accordance with their respective protocol rules, including
                but not limited to sending and receiving Crypto Assets to and
                from other users of a dedicated blockchain network.
              </p>
              <p>
                <span className="bold-section">3.3. </span>Towo Labs develops
                and operates non-custodial software and Services only, which
                means that we do not store or have any kind of access to your
                Crypto Assets, your Private Keys, your Recovery Phrase or your
                PIN code. We cannot send or receive Crypto Assets for you. Any
                Crypto Asset transfer occurs on blockchain networks and not on a
                network owned, controlled by Towo Labs or in any other manner
                under our supervision or control.
              </p>
              <h2>4. User Requirements</h2>
              <p>
                <span className="bold-section">4.1. </span>To be eligible to use
                Bifrost Wallet and our Services, you must be at least eighteen
                (18) years old or of other legal age according to your relevant
                jurisdiction. If you are using our Services on behalf of a legal
                entity, you further represent and warrant that:
              </p>
              <ul>
                <li>
                  <p>
                    <span className="bold-section">a) </span>the legal entity
                    that you represent is duly organized and validly existing
                    under the applicable laws of the jurisdiction of such legal
                    entity; and
                  </p>
                </li>
                <li>
                  <p>
                    <span className="bold-section">b) </span>you are duly
                    authorized by such legal entity to (i) act on its behalf and
                    (ii) entering into the Contract on behalf of the same.
                  </p>
                </li>
              </ul>
              <p>
                <span className="bold-section">4.2. </span>You represent and
                warrant that you are not on any trade or economic sanctions
                lists, placed under embargo, or otherwise appear on "Denied
                Person's List", "Denied Entity List", "Specially Designated
                Nationals" (issued by U.S. Department of Commerce) or similar.
                You also represent and warrant that you are not residing or
                being in a country or state placed under embargo, sanctions or
                similar decided by the EU or the US, or otherwise plan to use
                the Services in such a country or state.
              </p>
              <p>
                <span className="bold-section">4.3. </span>Should you for any
                reason be ineligible for our Services and/or be subject to any
                of the abovementioned restrictions, you undertake to terminate
                the Contract immediately and as from date when such restrictions
                apply for you. Should we, for any reasons be made aware that any
                of the abovementioned restrictions in section 4.2 apply for you,
                we reserve the right to undertake to terminate the Contract
                without notice.
              </p>
              <h2>5. Right to Use Bifrost Wallet</h2>
              <p>
                <span className="bold-section">5.1. </span>Subject to these
                Terms, Towo Labs grants you a worldwide, non-exclusive,
                non-transferable, free and revocable license to download,
                install, display and use the Bifrost Wallet and any of our
                Services provided therein on your device.
              </p>
              <p>
                <span className="bold-section">5.2. </span>The license in
                section 5.1 above and your use of Bifrost Wallet and our
                Services are also subject to the relevant Usage Rules.
              </p>
              <h2>6. Set up your Bifrost Wallet</h2>
              <p>
                <span className="bold-section">6.1. </span>In order to get
                started with Bifrost Wallet you must:
              </p>
              <ul>
                <li>
                  <p>
                    <span className="bold-section">a) </span>accept these Terms
                    and download the mobile application;
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">b) </span>generate a Recovery
                    Phrase in Bifrost Wallet or import an existing Recovery
                    Phrase, and keep your Recovery Phrase secure and
                    confidential;
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">c) </span>create and remember
                    a strong and unique PIN Code to your Bifrost Wallet; and
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">d) </span>comply with
                    applicable laws and the rules and regulations set out in the
                    Usage Rules.
                  </p>
                </li>
              </ul>
              <p>
                <span className="bold-section">6.2 </span>Your device must be
                compatible with the relevant system requirements and technical
                standards applicable for the Bifrost Wallet from time to time.
                Incompatibility in this regard may limit your use of Bifrost
                Wallet, completely or partially, as well as affect your user
                experience negatively. Information about the relevant system
                requirements is provided to you upon download.
              </p>
              <h2>7. Use of Bifrost Wallet</h2>
              <p>
                <span className="bold-section">7.1 </span>You are using the
                Bifrost Wallet at your own risk and for your own account.
              </p>
              <p>
                <span className="bold-section">7.2 </span>We cannot access your
                copy of the Bifrost Wallet once you have downloaded it to your
                mobile device. The security model therefore relies on your
                mobile device and your own handling of the Bifrost Wallet and
                the device. Before you approve any transaction or operation
                therein, you are responsible for always double-checking the
                information displayed on your device's screen and, if required,
                take any additional action (with Bifrost Wallet or other used
                means/technology) as to ensure that approval of the relevant
                transaction is accurate. Upon sending Crypto Assets, you are
                solely responsible for verifying that all transaction details
                are correct. We are not liable for any errors that occur in
                connection with your use of our Services.
              </p>
              <p>
                <span className="bold-section">7.3 </span>Your credentials and
                all other information are stored locally on your device. You are
                solely responsible on your own for remembering, protecting and
                storing your PIN Code, Private Keys, Recovery Phrase and all
                other information and/or credentials necessary for your use of
                Bifrost Wallet and/or your Crypto Assets in a secure manner and
                to protect the information from third parties. We cannot help
                you retrieve any such information. Any third-party with
                knowledge of your Recovery Phrase or PIN Code can gain control
                of the Private Keys associated with your Bifrost Wallet or of
                the Recovery Phrase, and thereby control your Crypto Assets.
              </p>
              <p>
                <span className="bold-section">7.4 </span>All transactions
                created through our Services are confirmed and recorded in
                associated blockchain networks, not provided by us. Such
                networks are decentralized peer-to-peer networks run by
                independent third parties, which Towo Labs does not own, control
                or operate. We have no control over the blockchain networks and
                their corresponding Crypto Assets, and we are not participating
                in or otherwise being a party to the transactions you engage in.
                Bifrost Wallet is only infrastructure displaying the Crypto
                Assets you possess. Therefore, we cannot and do not ensure that
                the transactions you participate in through our Services will be
                confirmed and processed by the relevant blockchain networks. We
                do not store, send, or receive Crypto Assets, and the
                transactions you configure on our Services may fail, or may be
                substantially delayed, by the underlying blockchain networks.
              </p>
              <p>
                <span className="bold-section">7.5 </span>We may in our
                discretion choose which blockchain networks and consequently
                which Crypto Assets Bifrost Wallet can be used for. Information
                about which Crypto Assets and blockchain networks currently
                supported are available at support.bifrostwallet.com.
              </p>
              <p>
                <span className="bold-section">7.6 </span>By using our Services,
                you agree not to copy, modify, adapt, decompile, disassemble,
                reverse engineer, or otherwise attempt to reconstruct or
                discover the construction of the Bifrost Wallet and any
                corresponding Materials.
              </p>
              <p>
                <span className="bold-section">7.7 </span>Any use of the Bifrost
                Wallet, any Materials and the Services in violation of these
                Terms, the applicable Usage Rules and/or the laws applicable to
                you and/or us, is prohibited. Inter alia, the following
                activities are examples, but not a complete list, of activities
                prohibited:
              </p>
              <ul>
                <li>
                  <p>
                    <span className="bold-section">a) </span>Distribution of any
                    malware, malicious code or similar through our Services.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">b) </span>Provision of false,
                    inaccurate or misleading information.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">c) </span>Actions to impose
                    unreasonable or disproportionate large loads on our
                    Services' or any Third-Party Services' infrastructure.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">d) </span>Circumvention or
                    bypassing, or attempts to bypass or circumvent, any security
                    or safety measure that we may use in or for our Services.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">e) </span>Using our Services
                    to pay for, support, or otherwise engage in any illegal
                    activities including but not limited to fraud, illegal
                    gambling, money laundering, or terrorist activities, or
                    encouraging or inducing any third party to engage in any of
                    the activities prohibited.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">f) </span>Using or attempts
                    to use another user's Bifrost Wallet without authorization
                    or use our Services in any manner that could interfere with,
                    disrupt, negatively affect, or inhibit other Users from
                    using our Services.
                  </p>
                </li>
              </ul>
              <h2>8. Payment and Fees</h2>
              <p>
                <span className="bold-section">8.1. </span>Bifrost Wallet is
                provided to you free of charge and we do not charge you for your
                use. However, we reserve the right to charge fees for our
                Services (or certain parts of our Services) in the future. If
                so, we will notify you beforehand and enable you to make use of
                the free Services and/or Services subject to certain fees,
                respectively as per the applicable Terms.
              </p>
              <h2>9. Right to Change your Mind</h2>
              <p>
                <span className="bold-section">9.1. </span>As Bifrost Wallet is
                a mobile application that you download, you acknowledge and
                agree that our delivery shall be considered complete as soon as
                your download of Bifrost Wallet is completed. By downloading the
                Bifrost Wallet you thereby waive your right to withdrawal from
                our Contract, if any such right is applicable for you under the
                relevant Usage Rules or applicable statutory legislation.
              </p>
              <p>
                <span className="bold-section">9.2. </span>However, if you
                change your mind and do not want to use Bifrost Wallet, you
                always have the right to terminate the Contract without charge
                regardless how long you have used Bifrost Wallet. Please see our
                description on how to terminate our Contract in section 15.2
                below.
              </p>
              <h2>10. Maintenance and Changes of Bifrost Wallet</h2>
              <p>
                <span className="bold-section">10.1. </span>On occasions, the
                blockchain protocol of a given Crypto Asset may change, which
                may affect key characteristics of a Crypto Asset, including but
                not limited to its availability, name, security, valuation or
                the way it operates. Forks entail that forked Crypto Assets may
                be misdirected or replicated. In any such events, Towo Labs may
                decide to suspend support of the impacted Crypto Asset for as
                long as Towo Labs deems necessary.
              </p>
              <p>
                <span className="bold-section">10.2. </span>We may restrict
                access to the Bifrost Wallet, temporarily or indefinitely, in
                order to perform necessary updates and amendments, such as but
                not limited to software updates, maintenance operations,
                amendments to the servers, debugging etc. We will make
                reasonable efforts to give you prior notice of any significant
                disruption of our Services. However, you can always recover your
                Private Keys using your Recovery Phrase on any other compatible
                Wallet even though our Services are unavailable or suspended.
              </p>
              <p>
                <span className="bold-section">10.3. </span>Updates will be
                available for download at the platforms relevant for and
                supported by your mobile device. Although you are free to choose
                whether to install the updates provided, we highly recommend you
                keep your Bifrost Wallet updated. If you decide not to install
                the updates that we provide, you should, however, not expect
                your Bifrost Wallet to remain in conformity with these Terms or
                otherwise remain secure. Your decision not to install updates
                may affect your use of Bifrost Wallet negatively and give rise
                to potential security issues in your Bifrost Wallet.
              </p>
              <p>
                <span className="bold-section">10.4. </span>We reserve the right
                to perform immediate changes and/or restrictions in Bifrost
                Wallet and our Services if we deem it necessary, for example due
                to compliance with applicable laws and regulations as well as
                orders from courts or public authorities, due to security
                reasons or similar. We may also restrict access to Bifrost
                Wallet wholly or partly in certain jurisdictions and states, if
                necessary, for us to comply with applicable laws.
              </p>
              <p>
                <span className="bold-section">10.5. </span>If there is any
                problem with the Bifrost Wallet or if you are dissatisfied in
                any way, please contact us and we will make our best efforts in
                order to meet your request. Our contact details are stated
                below. If you contact us, please note that we will process the
                personal data you provide us with, subject to our current
                privacy policy. Please note that Apple and/or Google have no
                obligations to furnish any maintenance and support services with
                respect to the Bifrost Wallet.
              </p>
              <h2>11. Third Party Services</h2>
              <p>
                <span className="bold-section">11.1. </span>Bifrost Wallet may
                reference or provide access to Third-Party Services through an
                in-app browser. We retain the exclusive right to add to, modify,
                or cancel the availability of any Third-Party Service for any
                reason and without prior notice. Towo Labs are never liable
                towards you for any Third-Party Service and/or its content or
                Third-Party Materials.
              </p>
              <p>
                <span className="bold-section">11.2. </span>Your use of
                Third-Party Services that you browse in your Bifrost Wallet is
                subject to separate terms and conditions between you and the
                Third Party. It is your own responsibility to review the
                third-party's terms and policies before using a Third-Party
                Service.
              </p>
              <p>
                <span className="bold-section">11.3. </span>Some Third-Party
                Services may in addition request or require access to your
                personal data. The processing of such data will be handled in
                accordance with the relevant Third Party's privacy policy and
                best practices. We are not the controller and/or processor of
                such processing of personal data. In addition, we do not share
                your data with Third-Party Service providers.
              </p>
              <p>
                <span className="bold-section">11.4. </span>We are not providing
                support to you for issues caused by or related to Third-Party
                Services. If you have questions or concerns about how a
                Third-Party Service operates, or need support, please contact
                the relevant Third Party directly.
              </p>
              <h2>12. Intellectual Property Rights</h2>
              <p>
                <span className="bold-section">12.1. </span>Bifrost Wallet, the
                Services, all Materials and all intellectual property rights
                associated therewith, including but not limited to copyrights,
                trademarks, domains, algorithms, methods, know-how or similar,
                are and will always remain the sole and exclusive property of
                Towo Labs, its affiliates and/or their respective licensors
                (including open source distributors and other non-proprietary
                license schemes). You are not granted any right, title or
                interest in or to Bifrost Wallet or the intellectual property
                associated therewith, except as expressly set forth in these
                Terms.
              </p>
              <p>
                <span className="bold-section">12.2. </span>You receive no
                rights and agree not to: (i) copy, sub-license, reverse
                engineer, lease, rent or assign Bifrost Wallet or any content
                (including Third-Party content), Materials or data included
                therein or associated therewith; or (ii) distribute in whole or
                in part, modify, or create derivatives of Bifrost Wallet or any
                content, material or data included therein, with exception of
                your management and configurations of your Crypto Assets.
              </p>
              <p>
                <span className="bold-section">12.3. </span>Bifrost Wallet is
                developed with software, source code and other elements that are
                provided by third parties, including open source software
                ("OSS"). OSS is subject to the license terms accompanying such
                OSS ("OSS License Terms"), and they are included in our
                third-party copyright notices, available in Bifrost Wallet. In
                case of discrepancies between these Terms and any of the OSS
                License Terms applicable to your use of Bifrost Wallet, the
                possibly more restrictive regulations shall apply, with the
                following exception: to the extent the provider of OSS
                explicitly permits or demands the activities otherwise
                prohibited pursuant to these Terms, the OSS License Terms shall
                prevail.
              </p>
              <p>
                <span className="bold-section">12.4. </span>To the extent your
                right to use Bifrost Wallet are subject to OSS License Terms,
                your rights to use such OSS shall in no way be restricted by
                these Terms. We will inform you about the use and the terms of
                use of the OSS and provide the terms of use if so required.
              </p>
              <p>
                <span className="bold-section">12.5. </span>You acknowledge that
                Apple and/or Google are never liable for claims from Third
                Parties regarding that Bifrost Wallet and the Services and/or
                your use of Bifrost Wallet infringe any intellectual property
                rights of such Third Party.
              </p>
              <h2>13. Indemnity</h2>
              <p>
                <span className="bold-section">13.1. </span>If you are using
                Bifrost Wallet in breach of these Terms, you shall indemnify and
                hold Towo Labs harmless for all costs and expenses (including
                reasonable costs for legal counsel) related to such breach.
              </p>
              <h2>14. Disclaimer and Limitation of Liability</h2>
              <p>
                <span className="bold-section">14.1. </span>BIFROST WALLET AND
                OUR SERVICES ARE PROVIDED TO YOU "AS IS" AND ANY EXPRESS OR
                IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                PURPOSE ARE EXPRESSLY DISCLAIMED. WE DO NOT WARRANT THAT BIFROST
                WALLET IS ERROR-FREE OR CAN BE USED UNINTERRUPTED. IN NO EVENT
                SHALL TOWO LABS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
                LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF
                CRYPTO ASSETS, USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION)
                HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN
                CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
                OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE,
                EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
              </p>
              <p>
                <span className="bold-section">14.2. </span>YOU EXPRESSLY
                UNDERSTAND AND AGREE THAT TOWO LABS AND ITS DIRECTORS AND
                EMPLOYEES SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF CRYPTO
                ASSETS, PROFITS, GOODWILL, USE, DATA, COST OF PROCUREMENT OF
                SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSSES,
                RESULTING FROM:
              </p>
              <ul>
                <li>
                  <p>
                    <span className="bold-section">a) </span>YOUR USE OR
                    INABILITY TO USE BIFROST WALLET AND OUR SERVICES AS WELL AS
                    HANDLING YOUR CRYPTO ASSETS;
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">b) </span>ANY CHANGES MADE TO
                    OUR SERVICES OR ANY RESTRICTION, SUSPENSION OR CESSATION OF
                    BIFROST WALLET AND OUR SERVICES OR ANY PART THEREOF;
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">c) </span>ANY UNAUTHORIZED
                    ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS, DATA OR
                    CRYPTO ASSETS;
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">d) </span>THE DELETION OF,
                    CORRUPTION OF, OR FAILURE TO STORE OR SEND OR RECEIVE YOUR
                    TRANSMISSIONS, DATA OR CRYPTO ASSETS ON OR THROUGH OUR
                    SERVICES; AND
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">e) </span>ANY OTHER MATTER
                    RELATING TO YOUR CRYPTO ASSETS, BIFROST WALLET AND OUR
                    SERVICES.
                  </p>
                </li>
              </ul>
              <p>
                <span className="bold-section">14.3. </span>THE RESTRICTIONS AND
                LIMITATIONS SET OUT IN THIS SECTION 14 SHALL APPLY IN THEIR
                ENTIRETY AND OPERATE SEPARATELY. IF ANY PART OF THE SAID
                PROVISIONS ARE DECLARED AS INVALID, VOID OR INOPERABLE, THE
                REMAINING PROVISIONS SHALL TO THE FULLEST EXTENT PERMITTED UNDER
                APPLICABLE LAW BE INTERPRETED AS TO ENSURE THE ORIGINALLY
                INTENDED EFFECTS THEREOF. THIS SECTION 14 SHALL APPLY ALSO FOR
                YOU AS A CONSUMER, EXCEPT TO THE EXTENT YOU UNDER STATUTORY LAW
                OR REGULATIONS ARE GRANTED CERTAIN RIGHTS. SHOULD YOU BE A
                CONSUMER – OR ANY OTHER INDIVIDUAL PROTECTED UNDER STATUTORY LAW
                OR REGULATIONS – THE PROVISIONS IN THIS SECTION 14 SHALL APPLY
                SUPPLEMENTARY TO ANY SUCH RIGHT GRANTED TO YOU UNDER STATUTORY
                LAW OR REGULATIONS.
              </p>
              <p>
                <span className="bold-section">14.4. </span>It is your own
                responsibility to educate yourself and keep yourself informed
                about blockchain networks and Crypto Assets when using Bifrost
                Wallet. You acknowledge that you have been warned of the
                following associated risks which are outside our control:
              </p>
              <ul>
                <li>
                  <p>
                    <span className="bold-section">a) </span>Regulatory changes.
                    Changes in laws and regulations may affect us and your use
                    of Bifrost Wallet and your handling of your Crypto Assets
                    therein. You should be fully aware of and compliant with
                    current and future regulations relating to and/or affecting
                    your use of Bifrost Wallet and our Services.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">b) </span>Tax. Transactions
                    with Crypto Assets and/or other Crypto Assets events may be
                    considered tax events in certain jurisdictions. We therefore
                    strongly encourage you to consult your own tax or accounting
                    adviser before participating in Crypto Assets events.
                  </p>
                </li>

                <li>
                  <p>
                    <span className="bold-section">c) </span>Fluctuations in
                    value. Crypto Assets may fluctuate in value. There is a
                    chance and a risk your Crypto Assets may get a higher or
                    lower value while its corresponding Private Keys are stored
                    in your Bifrost Wallet and/or when you are using our
                    Services. We are neither responsible nor able to advise you
                    in this regard.
                  </p>
                </li>
              </ul>
              <p>
                <span className="bold-section">14.5. </span>You acknowledge that
                Apple and/or Google will, to the extent permissible in
                accordance with applicable law, never have any responsibility
                and/or liability for Bifrost Wallet and any of our Services or
                any claims towards you and/or us.
              </p>
              <h2>15. Term and Termination</h2>
              <p>
                <span className="bold-section">15.1. </span>These Terms must be
                accepted by you before you start using Bifrost Wallet, and
                consequently the Contract between us enter into force upon
                acceptance of these Terms and/or when you start using Bifrost
                Wallet.
              </p>
              <p>
                <span className="bold-section">15.2. </span>You may terminate
                the Contract with us at any time and with immediate effect by
                stopping to use Bifrost Wallet and uninstalling the mobile
                application from your mobile device. Please be advised that your
                Bifrost Wallet only exist on your mobile device, i.e. we do not
                have any backups for you and cannot help you retrieve your
                information and/or Crypto Assets. Therefore, you need to save
                all information and data necessary to restore access to your
                Crypto Assets before you uninstall the mobile application. Your
                access to other Wallets (not being Bifrost Wallet) is dependent
                on whether you securely backed-up your Recovery Phrase.
              </p>
              <h2>16. Processing of Personal Data</h2>
              <p>
                <span className="bold-section">16.1. </span>As a basis, we do
                not process your personal data. All information necessary for
                your use are generated by your copy of Bifrost Wallet and stored
                locally in your mobile device. If you access or browse
                Third-Party Services in Bifrost Wallet, you may be asked to
                provide your personal data to the Third Party. We are neither
                sharing any personal data with such Third Parties nor are we
                responsible for the processing of the personal data. If you are
                providing personal data to Third Parties you are doing so
                directly to them, not to us.
              </p>
              <p>
                <span className="bold-section">16.2. </span>However, in certain
                cases we may process personal data that you have provided us
                with, for example when you are contacting us. Information about
                our processing of personal data, as well as your rights in
                connection therewith, are set out in our Privacy Policy for
                Bifrost Wallet.
              </p>
              <h2>17. Changes to these Terms</h2>
              <p>
                <span className="bold-section">17.1. </span>We reserve the right
                to, and may without further notice, alter, amend or modify these
                Terms from time to time. The amended Terms will be deemed
                effective immediately after we have adopted them and posted the
                latest version in Bifrost Wallet.
              </p>
              <p>
                <span className="bold-section">17.2. </span>Irrespective which
                version of the Terms that was in force when you first accepted
                the Terms, the latest version of these Terms will always be
                applicable to your use of Bifrost Wallet and our Services and
                thus constitute the Contract between you and Towo Labs.
              </p>
              <h2>18. Force Majeure</h2>
              <p>
                <span className="bold-section">18.1. </span>Towo Labs shall be
                relieved from liability for a failure to perform any obligation
                due to any circumstance which impedes, delays or aggravates any
                obligation to be fulfilled by us in accordance with these Terms,
                such as but not limited to changes in laws and regulations or in
                the interpretation thereof, acts of authorities, war, acts of
                war, labor disputes, blockades, major accidents, disrupted
                telecommunications and restricted access to Internet, attacks
                from hackers, virus, malware or similar, outbreak of epidemics
                and pandemics, and currency restrictions.
              </p>
              <h2>19. Miscellaneous</h2>
              <p>
                <span className="bold-section">19.1. </span>Severability. If any
                provision shall be deemed by any court to be invalid, illegal,
                or unenforceable, such provision will be enforced to the maximum
                extent permissible by law, and the validity, legality and
                enforceability of the remaining provisions hereof shall not be
                affected or impaired.
              </p>
              <p>
                <span className="bold-section">19.2. </span>Entire Agreement.
                These Terms constitute the entire and exclusive understanding
                and agreement between Towo Labs and you regarding our Services
                and supersede any prior oral or written understandings or
                agreements, if any, between Towo Labs and you regarding our
                Services.
              </p>
              <p>
                <span className="bold-section">19.3. </span>Assignment. You are
                not allowed to assign your obligations and/or your rights under
                these Terms. You acknowledge and agree that Towo Labs may assign
                its rights and obligations under these Terms of Use and, in such
                context, share or transfer information provided by you while
                using our Services, if any, to a third-party. You agree that
                Apple and Google has the right to benefit from these Terms, and,
                if necessary, apply these Terms towards you.
              </p>
              <h2>20. Governing Law and Disputes</h2>
              <p>
                <span className="bold-section">20.1. </span>Unless any
                applicable mandatory laws entitles you to any applicable law or
                other dispute resolution venue than set out in section 20.2
                below, our Contract (including the Terms) shall be construed in
                accordance with and be governed by the laws of Sweden, without
                regard to its conflicts of law principles.
              </p>
              <p>
                <span className="bold-section">20.2. </span>Subject to section
                20.1, any dispute, controversy or claim arising out of or in
                connection with the Contract (including the Terms), or the
                breach, termination or invalidity thereof, shall be finally
                settled by Swedish public courts, with Stockholm District Court
                as first instance.
              </p>
              <h2>21. Ideas or Questions?</h2>
              <p>
                If you have any feedback, comments or suggestions of
                improvements of Bifrost Wallet or our Services, you are welcome
                to contact us at support@bifrostwallet.com
              </p>
            </article>
          </TextPageContainer>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default TermsOfUsePage;
