import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

const Container = styled.div`
  width: 100%;
  > div {
    max-width: 42rem;
    width: 100%;
    margin: 5rem auto;
    h1 {
      margin-bottom: 1.5rem;
    }
    .detail {
      margin-bottom: 4rem;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    > div {
      margin: 2rem auto;
      h1 {
        margin-bottom: 1.2rem;
        text-align: center;
      }
      .detail {
        text-align: center;
        font-size: 1rem;
      }
    }
  }
`;

export default function TextPageContainer({ children }) {
  return (
    <Container>
      <div>{children}</div>
    </Container>
  );
}
